import sha256 from 'sha256';

// Apis
import {loginUserApi, registerUserApi} from '../../api/auth';
import {updateUserApi} from '../../api/users';

// Enums
import { StorageParams } from '../../enums/Storages';

// Interfaces
import {UserInterface} from '../../interfaces/User';

// Utils
import JSUtil from '../../utils/JSUtil';

export function loginUser(
  dispatch: (arg0: {type: string; error?: any; data?: any}) => void,
  loginPayload: {email?: string; password: any; trackingUserId?: string;},
) {
  loginPayload.password = sha256(loginPayload.password);
  loginUserApi(loginPayload, (err: any, res: UserInterface) => {
    if (err) {
      dispatch({type: 'LOGIN_ERROR', error: err});
    } else {
      dispatch({type: 'LOGIN_ERROR', error: ''});
      dispatch({type: 'LOGIN_SUCCESS', data: res});
    }
  });
}

export function registerUser(
  dispatch: (arg0: {type: string; data: UserInterface}) => void,
  userPayload: UserInterface,
  callback: (err: any, res: any) => void,
) {
  userPayload.password = sha256(userPayload.password as string);
  registerUserApi(userPayload, (err: any, res: {user: UserInterface}) => {
    if (res && !err) {
      dispatch({type: 'SET_USER_DATA', data: res.user});
    }
    if (callback) {
      callback(err, res);
    }
  });
}

export function logoutUser(dispatch: (arg0: {type: string}) => void) {
  dispatch({type: 'LOGOUT'});
}

export function updateUser(
  dispatch: (arg0: {type: string; data: UserInterface}) => void,
  userPayload: {body: UserInterface; token: string},
  callback: (err: any, res: any) => void,
) {
  updateUserApi(userPayload, (err: any, res: {user: UserInterface}) => {
    if (res && !err) {
      dispatch({type: 'SET_USER_DATA', data: res.user});
    }
    if (callback) {
      callback(err, res);
    }
  });
}

export function setErrorMessageEmpty(
  dispatch: (arg0: {type: string; error: string}) => void,
) {
  dispatch({type: 'LOGIN_ERROR', error: ''});
}

export async function setAuthSyncContext(dispatch: {
  (arg0: {type: string; data: any}): void;
}) {
  const storageState = localStorage.getItem(StorageParams.AuthReducer),
    data = JSON.parse(storageState as string);
  if (!JSUtil.isEmpty(data)) {
    data.isLoading = false;
  }
  
  if (!JSUtil.isEmpty(storageState)) {
    dispatch({
      type: 'SET_USER_DATA',
      data: data,
    });
  }
}
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { browserName, browserVersion, osName } from "react-device-detect";
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import { getStudiesTypesApi } from '../../api/schools';

// Components
import BasicLayout from '../Common/Layout/Basic';
import Form from '../Common/Form/';
import {validateInputs} from '../Common/Form/Inputs';
import StepsSubHeader from '../Common/Form/Steps/SubHeader';

// Config
import ENV from '../../config/env.json';

// Context
import {
  useAuthStore,
  loginUser,
  registerUser
} from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { AppInfo } from '../../enums/App';
import { DropdownOptions, InputTypes } from '../../enums/Inputs';
import { LanguageResource } from '../../enums/Languages';
import { RoutesLinks } from '../../enums/Routes';
import { StorageParams } from '../../enums/Storages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Utils
import JSUtil from '../../utils/JSUtil';
import LanguageUtil from '../../utils/LanguageUtil';

interface PropsInterface {
  navigate: NavigateFunction
}

const Register = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    [firstname, setFirstName] = useState(''),
    [lastname, setLastName] = useState(''),
    [email, setEmail] = useState(''),
    [typeOfStudy, setTypeOfStudy] = useState(''),
    [password, setPassword] = useState(''),
    [passwordConfirm, setPasswordConfirm] = useState(''),
    [termsService, setTermsService] = useState(false),
    [privacyPolicy, setPrivacyPolicy] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    inputs = [
      [
        {
          name: "firstname",
          label: t(LanguageResource.Input + ':firstname'),
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setFirstName(val),
          value: firstname,
          required: true
        },
        {
          name: "lastname",
          label: t(LanguageResource.Input + ':lastname'),
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setLastName(val),
          value: lastname,
          required: true
        },
      ],
      {
        name: "email",
        label: t(LanguageResource.Input + ':email'),
        type: InputTypes.Email,
        placeholder: true,
        onChange: (val: string) => setEmail(val),
        value: email,
        required: true
      },
      {
        name: "typeOfStudy",
        label: t(LanguageResource.Input + ':typeOfStudy'),
        type: InputTypes.Dropdown,
        options: {
          type: DropdownOptions.Api,
          dataGetter: getStudiesTypesApi
        },
        placeholder: true,
        onChange: (val: string) => setTypeOfStudy(val),
        value: typeOfStudy,
        required: true,
        setLoading: () => setIsLoading
      },
      [
        {
          name: "password",
          label: t(LanguageResource.Input + ':password'),
          type: InputTypes.Password,
          placeholder: true,
          onChange: (val: string) => setPassword(val),
          value: password,
          required: true
        },
        {
          name: "passwordConfirm",
          label: t(LanguageResource.Input + ':passwordConfirm'),
          type: InputTypes.PasswordConfirm,
          placeholder: true,
          onChange: (val: string) => setPasswordConfirm(val),
          value: passwordConfirm,
          required: true
        },
      ],
      {
        name: "termsService",
        type: InputTypes.Checkbox,
        placeholder: true,
        onChange: (val: boolean) => setTermsService(val),
        value: termsService,
        label: t(LanguageResource.Common + ':agree'),
        onLabelClick: {
          url: AppInfo.TermsServiceUrl,
          label: t(LanguageResource.Auth + ':termsService'),
        },
        required: true
      },
      {
        name: "privacyPolicy",
        type: InputTypes.Checkbox,
        placeholder: true,
        onChange: (val: boolean) => setPrivacyPolicy(val),
        value: privacyPolicy,
        label: t(LanguageResource.Common + ':agree'),
        onLabelClick: {
          url: AppInfo.PrivacyPolicyUrl,
          label: t(LanguageResource.Auth + ':privacyPolicy'),
        },
        required: true
      },
    ];

  useEffect(() => {
    checkIfUserIsLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  const checkIfUserIsLoggedIn = () => {
    if (!JSUtil.isEmpty(authState)) {
      if (authState?.loggedIn) {
        setIsLoading(false);
        const redirectPath = localStorage.getItem(StorageParams.Path);
        if (
          !JSUtil.isEmpty(redirectPath) &&
          ![
            RoutesLinks.Jobs.Link,
            RoutesLinks.Login.Link,
            RoutesLinks.Register.Link,
            RoutesLinks.ForgotPassword.Link,
            RoutesLinks.ResetPassword.Link,
          ].includes(redirectPath as string)
        ) {
          localStorage.removeItem(StorageParams.Path);
          props.navigate(redirectPath as string, { replace: true });
        } else {
          props.navigate(RoutesLinks.Jobs.Link, { replace: true });
        }
      }
    }
  }

  const navigateBack = () => {
    props.navigate(RoutesLinks.Login.Link);
  }

  const handleSubmit = () => {
    const valid = validateInputs(inputs, t);
    if (valid) {
      setIsLoading(true);
      const data = {
        email: email.toLowerCase().trim(),
        firstname,
        lastname,
        password,
        language: LanguageUtil.getLanguage(),
        education: {
          typeOfStudy: parseInt(typeOfStudy, 10),
        },
        tracking: {
          company: localStorage.getItem(StorageParams.TrackingCompany) || '',
          platform: 'web',
          model: browserName,
          brand: osName,
          systemVersion: browserVersion,
          appVersion: ENV.WEB_VERSION
        },
        //trackingUserId is a ID to check if we have consultations that aren't connected to the user, this id would be used for the user
        trackingUserId: localStorage.getItem(StorageParams.UserId) as string 
      };

      registerUser(authDispatch, data, (err: any, res: any) => {
        if (res && !err) {
          localStorage.removeItem(StorageParams.TrackingCompany);
          Swal.fire({
            icon: SwalIcons.Success as SweetAlertIcon,
            title: t(SwalTitle.Success),
            text: t(LanguageResource.Auth + ':success_register'),
          }).then(() => {
            loginUser(authDispatch, {email: email.toLowerCase().trim(), password});
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      });
    }
  }

  return (
    <BasicLayout isLoading={isLoading}>
      <div className='sub-title-wrapper register-wrapper'>
        <StepsSubHeader
          title={''}
          goBack={navigateBack}
        />
        <Form 
          inputs={inputs}
          onSubmit={handleSubmit}
        />
        <div className="primary-button-wrapper" onClick={() => handleSubmit()}>
          <p className="primary-button-text">
            {t(LanguageResource.Auth + ':register')}
          </p>
        </div>
      </div>
    </BasicLayout>
  );
}

export default Register;